import React from 'react';
import { SvgIcon } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InnerPage } from './InnerPage';
import { MenuListItem } from '../components/nav/menu';
import { PATHS } from '../paths';

const primaryNav: MenuListItem[] = [
  /* {
    id: 'dashboard',
    type: '_menu_item_url',
    icon: (
      <SvgIcon>
        <FontAwesomeIcon icon="tachometer-alt" />
      </SvgIcon>
    ),
    text: 'Dashboard',
    url: PATHS.admin.main,
  }, */
  {
    id: 'reports',
    type: '_menu_item_url',
    icon: (
      <SvgIcon>
        <FontAwesomeIcon icon="chart-bar" />
      </SvgIcon>
    ),
    text: 'Reports',
    url: PATHS.admin.reports,
  },
];

const secondaryNav: MenuListItem[] = [
  {
    id: 'admin-access-requests',
    type: '_menu_item_url',
    icon: (
      <SvgIcon>
        <FontAwesomeIcon icon="user-plus" />
      </SvgIcon>
    ),
    text: 'Access Requests',
    url: PATHS.admin.accessRequests,
  },
  {
    id: 'admin-active-bundles',
    type: '_menu_item_url',
    icon: (
      <SvgIcon>
        <FontAwesomeIcon icon="ball-pile" />
      </SvgIcon>
    ),
    text: 'Active Bundles',
    url: PATHS.admin.activeBundles,
  },
  {
    id: 'admin-upload-sites',
    type: '_menu_item_url',
    icon: (
      <SvgIcon>
        <FontAwesomeIcon icon="folder-upload" />
      </SvgIcon>
    ),
    text: 'Upload Sites',
    url: PATHS.admin.uploadSites,
  },
  {
    id: 'admin-organizations',
    type: '_menu_item_url',
    icon: (
      <SvgIcon>
        <FontAwesomeIcon icon="address-book" />
      </SvgIcon>
    ),
    text: 'Organizations',
    url: PATHS.admin.organizations,
  },
  {
    id: 'create-ghost-org',
    type: '_menu_item_url',
    icon: (
      <SvgIcon>
        <FontAwesomeIcon icon="ghost" />
      </SvgIcon>
    ),
    text: 'Add Ghost SP',
    url: PATHS.admin.createGhostOrg,
  },
  {
    id: 'admin-sites',
    type: '_menu_item_url',
    icon: (
      <SvgIcon>
        <FontAwesomeIcon icon="map-marker-alt" />
      </SvgIcon>
    ),
    text: 'All Sites',
    url: PATHS.admin.sites,
  },
  {
    id: 'bid-allowances',
    type: '_menu_item_url',
    icon: (
      <SvgIcon>
        <FontAwesomeIcon icon="flask" />
      </SvgIcon>
    ),
    text: 'Bid Allowances',
    url: PATHS.admin.spBidAllowances,
  },
  {
    id: 'sp-bundles',
    type: '_menu_item_url',
    icon: (
      <SvgIcon>
        <FontAwesomeIcon icon="users" />
      </SvgIcon>
    ),
    text: 'Invite Gaps',
    url: PATHS.admin.bundlesAvailableForSp,
  },
];

export const AdminPage: React.FC = ({ children }) => (
  <InnerPage navType="admin" primaryNav={primaryNav} secondaryNav={secondaryNav}>
    {children}
  </InnerPage>
);
