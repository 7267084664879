export const adminBasePath = '/admin';
export const spBasePath = '/sp';
export const fmBasePath = '/fm';
const requestAccessBasePath = '/request-access';

export const ADMIN_UI_URL_PREFIX = 'admin';

export const PATHS = {
  home: '/',
  loginStepTwo: '/login-step-two',
  loginMagicLinkRequest: '/request-email-link',
  loginWithPassword: '/login-password',
  forgotPassword: '/forgot-password',
  loginWithEmailLinkInstructions: '/login-with-email-link',
  acceptInvitation: '/accept-invitation/:inviteId',
  declineBundle: '/decline/:inviteId/bundle/:bundleId',
  requestAccess: {
    start: requestAccessBasePath,
    sp: `${requestAccessBasePath}/service-provider`,
    spSignUp: `${requestAccessBasePath}/service-provider/sign-up`,
    fm: `${requestAccessBasePath}/facility-manager`,
    fmSignUp: `${requestAccessBasePath}/facility-manager/sign-up`,
    complete: {
      sp: `${requestAccessBasePath}/thank-you/sp`,
      fm: `${requestAccessBasePath}/thank-you/fm`,
    },
    pending: `${requestAccessBasePath}/pending`,
    rejected: `${requestAccessBasePath}/rejected`,
    deactivated: `${requestAccessBasePath}/deactivated`,
  },
  register: {
    sp: '/register/service-provider',
    spByInvite: '/register/service-provider/invite/:inviteId',
    spSignUp: '/register/service-provider/sign-up',
  },
  admin: {
    main: adminBasePath,
    reports: `${adminBasePath}/reports`,
    reportDetail: `${adminBasePath}/report/:reportId`,
    activeBundles: `${adminBasePath}/active-bundles`,
    accessRequests: `${adminBasePath}/access-requests`,
    accessRequestsApproved: `${adminBasePath}/access-requests/approved`,
    accessRequestsRejected: `${adminBasePath}/access-requests/rejected`,
    uploadSites: `${adminBasePath}/upload-sites`,
    userProfile: `${adminBasePath}/user/profile`,
    passwordUpdated: `${adminBasePath}/user/profile/password-updated`,
    organizations: `${adminBasePath}/organizations`,
    sites: `${adminBasePath}/sites`,
    createGhostOrg: `${adminBasePath}/create-ghost-organization`,
    spBidAllowances: `${adminBasePath}/bid-allowances`,
    bundlesAvailableForSp: `${adminBasePath}/sp-available-bundles`,
  },
  sp: {
    main: spBasePath,
    reports: `${spBasePath}/reports`,
    reportDetail: `${spBasePath}/report/:reportId`,
    onboarding: {
      welcome: `${spBasePath}/onboarding/welcome`,
      main: `${spBasePath}/onboarding`,
      profile: `${spBasePath}/onboarding/profile`,
      key_contacts: `${spBasePath}/onboarding/key_contacts`,
      services: `${spBasePath}/onboarding/services`,
      documents: `${spBasePath}/onboarding/documents`,
      diversity: `${spBasePath}/onboarding/diversity`,
      review: `${spBasePath}/onboarding/review`,
    },
    organizationInfo: {
      main: `${spBasePath}/organization/info`,
      services: `${spBasePath}/organization/info/services`,
      diversity: `${spBasePath}/organization/info/diversity`,
      files: `${spBasePath}/organization/info/files`,
      team: `${spBasePath}/organization/info/team`,
      billing: `${spBasePath}/organization/info/billing`,
      invoices: `${spBasePath}/organization/info/invoices`,
      blacklisted: `${spBasePath}/organization/info/blacklisted`,
    },
    organizationService: `${spBasePath}/organization/services/:slug`,
    organizationSiteDetail: `${spBasePath}/organization/sites/:siteId`,
    userProfile: `${spBasePath}/user/profile`,
    passwordUpdated: `${spBasePath}/user/profile/password-updated`,
    bundles: `${spBasePath}/bundles`,

    envelopes: `${spBasePath}/bundles/envelopes`,
    blacklisted: `${spBasePath}/bundles/blacklisted`,
    invoices: `${spBasePath}/bundles/invoices`,
    billing: `${spBasePath}/billing`,
    billingInvoices: `${spBasePath}/billing/invoices`,
    team: `${spBasePath}/team`,
    profile: `${spBasePath}/profile`,
    services: `${spBasePath}/services`,
    diversity: `${spBasePath}/diversity`,
    documents: `${spBasePath}/documents`,

    bundleDetail: `${spBasePath}/bundles/:bundleId(\\d+)`,
    bid: `${spBasePath}/bids/:bidId`,
    bidDocuments: `${spBasePath}/bids/:bidId/documents`,
    bidScreenerAnswers: `${spBasePath}/bids/:bidId/screener-answers`,
    messages: `${spBasePath}/messages`,
    messagesThread: `${spBasePath}/messages/:threadId`,
    chat: `${spBasePath}/chat`,
    chatChannel: `${spBasePath}/chat/:channelId`,
  },
  fm: {
    main: fmBasePath,
    dashboard: `${fmBasePath}/dashboard`,
    reports: `${fmBasePath}/reports`,
    reportDetail: `${fmBasePath}/report/:reportId`,
    organizationInfo: {
      main: `${fmBasePath}/organization/info`,
      files: `${fmBasePath}/organization/info/files`,
      team: `${fmBasePath}/organization/info/team`,
      regions: `${fmBasePath}/organization/info/regions`,
    },
    organizationServices: `${fmBasePath}/organization/services/:slug`,
    organizationSites: `${fmBasePath}/organization/sites`,
    organizationSiteCreate: `${fmBasePath}/organization/sites/create`,
    organizationSiteDetail: `${fmBasePath}/organization/sites/:siteId`,
    organizationSiteEdit: `${fmBasePath}/organization/sites/:siteId/edit`,
    organizationBundles: `${fmBasePath}/organization/bundles`,
    organizationAllBids: `${fmBasePath}/organization/bundles/bids`,
    organizationSPSites: `${fmBasePath}/organization/bundles/sites`,
    organizationInviteGaps: `${fmBasePath}/organization/bundles/invite-gaps`,
    organizationEnvelopes: `${fmBasePath}/organization/bundles/envelopes`,
    organizationBundleDetail: `${fmBasePath}/organization/bundles/:bundleId(\\d+)`,
    organizationBundleSites: `${fmBasePath}/organization/bundles/:bundleId(\\d+)/sites`,
    organizationBundleSettings: `${fmBasePath}/organization/bundles/:bundleId(\\d+)/settings`,
    organizationBundleInvite: `${fmBasePath}/organization/bundles/:bundleId(\\d+)/invite`,
    organizationBundleBidsCompare: `${fmBasePath}/organization/bundles/:bundleId(\\d+)/compare/:bidIds`,
    organizationContractTerms: `${fmBasePath}/organization/contract-terms`,
    organizationContractDetail: `${fmBasePath}/organization/contract-terms/:contractId`,
    organizationScreeners: `${fmBasePath}/organization/screeners`,
    organizationProviders: `${fmBasePath}/organization/providers`,
    userProfile: `${fmBasePath}/user/profile`,
    passwordUpdated: `${fmBasePath}/user/profile/password-updated`,
    bundler: `${fmBasePath}/bundler`,
    bid: `${fmBasePath}/bids/:bidId`,
    bidDocuments: `${fmBasePath}/bids/:bidId/documents`,
    bidScreenerAnswers: `${fmBasePath}/bids/:bidId/screener-answers`,
    messages: `${fmBasePath}/messages`,
    messagesThread: `${fmBasePath}/messages/:threadId`,
    chat: `${fmBasePath}/chat`,
    chatChannel: `${fmBasePath}/chat/:channelId`,
  },
  permalinks: {
    messages: '/messages',
    bid: '/bids/:bidId',
    bundleDetail: '/bundles/:bundleId',
    envelopes: '/envelopes',
  },
};
