import React, {
  createContext, useContext, useEffect, useMemo,
} from 'react';
import { useMediaQuery } from '@mui/material';
import { useLazyQuery } from '@apollo/react-hooks';
import { Sidebar } from '../../../../sidebar/Sidebar';
import { ServiceProviderSidebar } from './ServiceProviderSidebar';
import { SpSidebarTitle } from './SPSidebarTitle';
import { ISidebarSearch, useSidebarState } from '../../../../sidebar/useSidebarSearchState';
import { ServiceProvider, ServiceProviderVariables } from '../../../../../api/types/ServiceProvider';
import { SERVICE_PROVIDER } from '../../../../../api/organization/fm/serviceProvider';
import { SpProfileFileType } from '../../../../../api/types/globalTypes';

export interface SidebarAdditionalProps {
  page?: 'main' | 'documents' | 'diversity' | 'contacts';
  serviceId?: number;
  facilities?: any[];
}

export type SpSidebarExtraType = Record<string, any> | undefined;

export type SpSidebarExtraTypeValue = SpSidebarExtraType
| ((prev: SpSidebarExtraType) => SpSidebarExtraType);

const SpSidebarContext = createContext({
  state: undefined as undefined | ISidebarSearch,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  openSidebar: (serviceProviderId: number, name: string, ctx: SidebarAdditionalProps) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  modifyExtra: (v: SpSidebarExtraTypeValue) => {},
  closeSidebar: () => {},
});

interface SpSidebarProviderProps {
  children: React.ReactNode;
}

export const useSpSidebarHandlers = () => useContext(SpSidebarContext);

export const SpSidebarProvider: React.FC<SpSidebarProviderProps> = ({ children }) => {
  const [state, setState] = useSidebarState();

  const value = useMemo(() => ({
    state,
    openSidebar: (serviceProviderId: number, name: string, ctx: SidebarAdditionalProps) => {
      setState({
        sb_type: 'sp',
        sb_id: serviceProviderId,
        sb_title: name,
        sb_extra: ctx,
      });
    },
    modifyExtra: (extra: SpSidebarExtraTypeValue) => {
      setState((prev) => (prev ? ({
        ...prev,
        sb_extra: typeof extra === 'function' ? extra(prev.sb_extra) : extra,
      }) : undefined));
    },
    closeSidebar: () => {
      setState(undefined);
    },
  }), [state, setState]);

  const sidebarSpOpen = state?.sb_type === 'sp' && !!state?.sb_id;
  const id = state?.sb_id;
  const [getOrgData, orgData] = useLazyQuery<
    ServiceProvider,
    ServiceProviderVariables
  >(SERVICE_PROVIDER, {
    fetchPolicy: 'no-cache',
  });
  useEffect(() => {
    if (sidebarSpOpen) {
      getOrgData({
        variables: { organizationId: id! },
      });
    }
  }, [getOrgData, sidebarSpOpen, id]);

  const logo = orgData.data?.organization?.spProfile?.files
    .find((f) => f.type === SpProfileFileType.LOGO);

  const sm = useMediaQuery((t: any) => t.breakpoints.down('md'));

  return (
    <SpSidebarContext.Provider value={value}>
      <Sidebar
        width={sm ? window.innerWidth : 633}
        disableTitleBorderBottom={!state?.sb_extra?.page || state?.sb_extra?.page === 'main'}
        open={sidebarSpOpen}
        onClose={value.closeSidebar}
        title={sidebarSpOpen ? (
          <SpSidebarTitle
            spOrganizationId={state!.sb_id}
            title={state!.sb_title}
            logo={logo?.url}
          />
        ) : ''}
        content={(sidebarSpOpen ? (
          <ServiceProviderSidebar
            orgData={orgData}
            organizationId={state!.sb_id}
            serviceId={state!.sb_extra?.serviceId}
            facilities={state?.sb_extra?.facilities ?? []}
          />
        ) : null)}
      >
        {children}
      </Sidebar>
    </SpSidebarContext.Provider>
  );
};
