import React, {
  useCallback, useMemo, useRef, useState,
} from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import {
  Grid, SvgIcon,
  Tooltip,
} from '@mui/material';

import {
  Users, Users__users_items, UsersVariables,
} from 'api/types/Users';
import { USERS, USERS_QUERY } from 'api/user/users';
import { formatPhoneInternationalPretty } from 'utils/phone';
import { UserType, WhereUserInput } from 'api/types/globalTypes';
import { useProfile } from 'api/user/profile';
import { Permissions, PERMISSIONS_AUTOCOMPLETE } from 'api/permissions/permissions';
import { PermissionsAutocomplete, PermissionsAutocompleteVariables } from 'api/types/PermissionsAutocomplete';
import { DeactivateUser, DeactivateUserVariables } from 'api/types/DeactivateUser';
import { DataGrid, DataGridRefHandle, DataGridState } from 'components/datagrid/DataGrid';
import { DataResult } from '@progress/kendo-data-query';
import { ColumnProps } from 'components/datagrid/column/Column';
import {
  filterUserPermissions,
  UserPermissionType,
} from 'components/organization/UserPermissionsComponent';
import { UserPermissionsDialog } from 'components/organization/UserPermissionsDialog';
import { ActionsMenu, ActionsMenuItems } from 'components/datagrid/actions/ActionsMenu';
import { sortBy } from 'lodash';
import { CircleCount } from 'components/common/CircleCount';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { orange } from '@mui/material/colors';
import { createStyles, makeStyles } from '@mui/styles';
import { UserNotifications } from 'components/user/profile/userNotifications/UserNotifications';
import { DateTime } from '../../../text/DateTime';
import { NavTypeNoAdmin } from '../../../nav/navType';
import { useDialog } from '../../../Dialog';
import { DEACTIVATE_USER } from '../../DeactivateUserMutation';
import { ErrorSnackbar, useErrorSnackbar } from '../../../notifications/ErrorSnackbar';
import { ErrorMessage } from '../../../notifications/ErrorMessage';
import { useModalControls } from '../../../common/useModalControls';
import { useUserPermissionChecker } from '../../../user/useUserPermissionChecker';
import { SuccessSnackbar } from '../../../notifications/SuccessSnackbar';
import { NoData } from '../../../text/NoData';
import { FilterSimple } from '../../../datagrid/filter/types';
import { FilterOperation } from '../../../table/filters/BaseFilter';

export interface UsersTableProps {
  navType: NavTypeNoAdmin;
}

export const useStyles = makeStyles((theme) => createStyles({
  icon: {
    marginLeft: theme.spacing(0.5),
    color: orange['400'],
    fontSize: '0.8rem',
  },
}));

export const UsersTable: React.FC<UsersTableProps> = ({
  navType,
}) => {
  const classes = useStyles();
  const profile = useProfile();
  const { confirm } = useDialog();
  const gridRef = useRef<DataGridRefHandle>(null);
  const hasPermissions = useUserPermissionChecker();

  const orgOwner = hasPermissions(Permissions.ORGANIZATION_OWNER);
  const inviteUsersAllowed = hasPermissions([
    Permissions.INVITE_USERS, Permissions.ORGANIZATION_OWNER,
  ]);

  const [currentUser, setCurrentUser] = useState<Users__users_items | null>(null);
  const [userPermissionsDialogVisible, setUserPermissionsDialogVisible] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const notificationDialog = useModalControls();

  const { data: availablePermsData, error: permsError } = useQuery<
    PermissionsAutocomplete,
    PermissionsAutocompleteVariables
  >(PERMISSIONS_AUTOCOMPLETE, {
    variables: { type: navType === 'fm' ? UserType.fm : UserType.sp },
    fetchPolicy: 'no-cache',
  });

  const tableId = 'users';
  const buildWhere = useCallback((state: DataGridState): WhereUserInput => ({
    and: state.apiFilters,
  }), []);

  const [fetchTableData, {
    data: tableData,
    loading: tableLoading,
    error: tableError,
  }] = useLazyQuery<Users, UsersVariables>(USERS, {
    fetchPolicy: 'no-cache',
  });

  const tableDataResult: DataResult = useMemo(() => ({
    data: tableData?._users ? tableData._users.items : [],
    total: tableData?._users?.total_count ?? 0,
  } as DataResult), [tableData]);

  const loadTableData = useCallback((state: DataGridState) => {
    fetchTableData({
      variables: {
        skip: state.skip ?? 0,
        take: state.take ?? 0,
        order: state.order,
        where: buildWhere(state),
      },
    });
  }, [buildWhere, fetchTableData]);

  const defaultFilters: { [field: string]: FilterSimple } = useMemo(() => ({
    status: {
      field: 'status',
      type: 'string',
      logic: undefined,
      where: {
        [FilterOperation.in]: ['active'],
      },
    },
  }), []);

  const columns: ColumnProps[] = useMemo(() => ([
    {
      field: 'fullName',
      title: 'Full Name',
      width: 150,
      render: ({ fullName }) => fullName,
      filterWidget: { filterType: 'string' },
    },
    {
      field: 'login_email',
      title: 'Login Email',
      width: 200,
      filterWidget: { filterType: 'string' },
    },
    {
      field: 'work_email',
      title: 'Notification Email',
      width: 200,
      filterWidget: { filterType: 'string' },
    },
    {
      field: 'phone_number',
      title: 'Phone',
      width: 150,
      render: ({ phone_number }) => (phone_number ? formatPhoneInternationalPretty(phone_number) : ''),
      filterWidget: { filterType: 'string' },
    },
    {
      field: 'created_on',
      title: 'Created On',
      width: 120,
      render: ({ created_on }) => <DateTime block value={created_on} short />,
    },
    {
      field: 'approved_on',
      title: 'Approved On',
      width: 120,
      render: ({ approved_on }) => <DateTime block value={approved_on} short />,
    },
    {
      field: 'last_action_on',
      title: 'Last Activity',
      width: 120,
      render: ({ last_action_on }) => <DateTime block value={last_action_on} short />,
    },
    {
      field: 'last_login_on',
      title: 'Last Login',
      width: 120,
      render: ({ last_login_on }) => <DateTime block value={last_login_on} short />,
    },
    {
      field: 'status',
      title: 'Status',
      width: 100,
      hiddenByDefault: true,
      filterWidget: {
        filterType: 'checkboxes',
        substituteOptionLabels: true,
        options: [
          { key: 'active', label: 'Active' },
          { key: 'deactivated', label: 'Deactivated' },
        ],
      },
    },
    {
      field: 'deactivated_on',
      title: 'Deactivated On',
      width: 150,
      render: ({ deactivated_on }) => (
        deactivated_on
          ? <DateTime block value={deactivated_on} short />
          : <NoData>-</NoData>
      ),
    },
    {
      field: 'permissions',
      title: 'Permissions',
      sortable: false,
      width: 120,
      render: ({ userPermissions, fullName, deactivated_on }: Users__users_items) => {
        if (permsError) {
          return (
            <ErrorMessage devMessage={permsError.message}>
              Could not load permissions
            </ErrorMessage>
          );
        }

        if (deactivated_on) {
          return <NoData>-</NoData>;
        }

        const availablePermissions = [] as UserPermissionType[];
        if (availablePermsData) {
          availablePermsData.permissions.forEach((permission) => {
            const globalPermission = {
              ...permission,
              name: `Global: ${permission.name}`,
              permission: { is_regional: permission.is_regional },
            };
            availablePermissions.push(globalPermission);

            if (permission.is_regional) {
              // There we creating fake names with RegionName + PermissionName
              // And append region_id for new objects
              availablePermsData._organizationRegions.items.forEach((region) => {
                const regionalPermission = {
                  name: `${region.name}: ${permission.name}`,
                  permission_id: permission.permission_id,
                  region_id: region.region_id,
                  permission: {
                    is_regional: true,
                  },
                };

                availablePermissions.push(regionalPermission);
              });
            }
          });
        }

        // We sort `availablePermission` with `sortBy`
        // from lodash for grouping permission by region
        const sortedPermissions = sortBy(availablePermissions, [(o) => o.name]);
        const filteredPermissions = sortedPermissions.filter(
          (ap) => userPermissions.find((up) => filterUserPermissions(ap, up)),
        );

        const isUserOrgOwner = filteredPermissions.some(
          (p) => p.permission_id === Permissions.ORGANIZATION_OWNER,
        );

        return (
          <Grid container alignItems="center">
            <Tooltip title={filteredPermissions.map((p) => p.name).join(', ')}>
              <CircleCount count={filteredPermissions.length} />
            </Tooltip>
            {isUserOrgOwner && (
              <Tooltip title={`${fullName} has OrganizationOwner permission`}>
                <SvgIcon className={classes.icon}>
                  <FontAwesomeIcon icon="crown" size="xs" />
                </SvgIcon>
              </Tooltip>
            )}
          </Grid>
        );
      },
    },
  ]), [availablePermsData, classes.icon, permsError]);

  const [
    deactivateUser,
    { loading: deactivateUserLoading, error: deactivateUserError },
  ] = useMutation<DeactivateUser, DeactivateUserVariables>(DEACTIVATE_USER, {
    refetchQueries: [USERS_QUERY],
  });
  const deactivateUserErrorSnackbar = useErrorSnackbar(deactivateUserError);
  const deactivateHandler = (user: Users__users_items) => async () => {
    const confirmed = await confirm({
      okText: 'Deactivate',
      okColor: 'secondary',
      title: 'Deactivate User',
      content: `Are you sure you want to deactivate ${user.first_name}?`,
    });

    if (confirmed) {
      await deactivateUser({
        variables: { user_id: user.user_id },
      });
    }
  };

  const buildMenuItems: (user: Users__users_items)
  => ActionsMenuItems<Users__users_items> = (row) => ([
    {
      title: `Deactivate${row.user_id === profile.user_id ? ' (you)' : ''}`,
      disabled: row.deactivated_on || deactivateUserLoading || row.user_id === profile.user_id,
      onClick: deactivateHandler(row),
    },
    {
      title: 'Permissions',
      onClick: () => {
        setCurrentUser(row);
        setUserPermissionsDialogVisible(true);
      },
      disabled: !orgOwner,
    },
    ...(profile.organization.is_service_provider ? [{
      title: 'Manage Notifications',
      onClick: () => {
        setCurrentUser(row);
        notificationDialog.onOpen();
      },
      disabled: !orgOwner,
    }] : []),
  ]);

  return (
    <>
      <DataGrid
        ref={gridRef}
        id={tableId}
        columns={columns}
        loadTableData={loadTableData}
        tableData={tableDataResult}
        tableLoading={tableLoading}
        tableError={tableError}
        defaultFilters={defaultFilters}
        actionsWidth={150}
        withMap={false}
        selectionType="none"
        actions={inviteUsersAllowed ? (row: Users__users_items) => ([
          <ActionsMenu
            data={row}
            items={buildMenuItems}
          />,
        ]) : undefined}
        topPageNavigation={false}
      />
      {orgOwner && (
        <>
          <UserPermissionsDialog
            user={currentUser}
            navType={navType}
            open={userPermissionsDialogVisible}
            onClose={() => {
              setUserPermissionsDialogVisible(false);
              if (gridRef.current) gridRef.current.reload();
            }}
          />
          {currentUser && (
            <UserNotifications
              services={profile.organization.services}
              user_id={currentUser.user_id}
              fullName={currentUser.fullName}
              onSuccess={(message) => {
                setSuccessMessage(message);
                if (gridRef.current) gridRef.current.reload();
                notificationDialog.onClose();
              }}
              dialogProps={notificationDialog}
            />
          )}
        </>
      )}
      <ErrorSnackbar {...deactivateUserErrorSnackbar}>
        Couldn
        &apos;
        t deactivate user
      </ErrorSnackbar>
      <SuccessSnackbar open={!!successMessage} onClose={() => setSuccessMessage(null)}>
        {successMessage}
      </SuccessSnackbar>
    </>
  );
};
