import React, { useState } from 'react';
import {
  Accordion as MuiAccordion, AccordionDetails as MuiAccordionDetails, AccordionProps,
  AccordionSummary as MuiAccordionSummary, AccordionSummaryProps, Box, Grid,
  Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { ArrowDropDown } from '@mui/icons-material';
import { useQuery } from '@apollo/react-hooks';
import { createStyles, makeStyles, styled } from '@mui/styles';
import { ApolloError } from '@apollo/client';

import { ServiceProvider } from 'api/types/ServiceProvider';
import { useProfile } from 'api/user/profile';
import { FM_CUSTOM_FIELDS } from 'api/fmCustomFields/fmCustomFields';
import { FmCustomFields, FmCustomFieldsVariables } from 'api/types/FmCustomFields';
import { Loading } from 'components/Loading';
import { ErrorMessage } from 'components/notifications/ErrorMessage';
import { MapMarker } from 'components/map/utils/marker';
import { SPOverview } from './components/SPOverview';
import { ServiceSpecific } from './components/ServiceSpecific';
import { useSpSidebarHandlers } from './SpSidebarProvider';
import { ContactsSubPage } from './components/ContactsSubPage';
import { DocumentsSubPage } from './components/DocumentsSubPage';
import { DiversitySubPage } from './components/DiversitySubPage';
import { FmFieldsSubPage } from './components/FmFieldsSubPage';
import { GallerySubPage } from './components/GallerySubPage';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowDropDown sx={{ fontSize: '2rem' }} />}
    {...props}
  />
))(({ theme }: { theme: Theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 1)',
  '&.Mui-expanded': {
    backgroundColor: theme.palette.primary.main,
  },
  '&.Mui-expanded .MuiAccordionSummary-content': {
    color: '#fff',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
    color: '#fff',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export interface ServiceProviderSidebarProps {
  organizationId: number;
  orgData: { data?: ServiceProvider, error?: ApolloError, loading: boolean };
  serviceId?: number;
  facilities?: MapMarker<number>[];
  adminForceShowSpEmail?: boolean,
}

const winScrollbarWidth = 17;
const isWin = navigator.userAgent.indexOf('Win') !== -1;
const sidebarWidth = Math.min(window.innerWidth, 633);
const slideLeftPx = sidebarWidth - (isWin ? winScrollbarWidth : 0);
const useStyles = makeStyles(() => createStyles({
  mainFrame: {
    marginLeft: '0px',
    transition: 'margin-left 250ms ease-out',
    flexWrap: 'nowrap',
    scrollbarWidth: 'thin',
  },
  frameItem: {
    width: '100%',
    minWidth: '100%',
    // [theme.breakpoints.down('md')]: {
    //   width: '100wv',
    //   minWidth: '100wv',
    // },
  },
  showSecondaryPage: {
    marginLeft: `-${slideLeftPx}px`,
    // [theme.breakpoints.down('md')]: {
    //   width: '100wv',
    //   transform: 'translateX(-100wv)',
    // },
  },
}));

export const ServiceProviderSidebar: React.FC<ServiceProviderSidebarProps> = ({
  organizationId,
  orgData: d,
  serviceId,
  facilities,
  adminForceShowSpEmail,
}) => {
  const { data: orgData, loading: orgLoading, error: orgError } = d;
  const classes = useStyles();
  const { state } = useSpSidebarHandlers();
  const profile = useProfile();

  // Get custom fields values for selected SP
  // We call it there, cause we need to know if we have some values at all (for Tab display)
  const {
    data: customFieldsData,
    error: customFieldsError,
  } = useQuery<FmCustomFields, FmCustomFieldsVariables>(FM_CUSTOM_FIELDS, {
    variables: {
      skip: 0,
      take: 9999,
    },
    fetchPolicy: 'no-cache',
  });
  const customFields = customFieldsData?.fmCustomFields.items;

  const org = orgData?.organization;
  const [expanded, setExpanded] = useState<number | undefined>(
    serviceId ?? profile.extra.last_service_id ?? org?.services[0].service_id,
  );
  const handleChange = (val: number) => () => setExpanded(
    (prev) => (prev === val ? undefined : val),
  );

  if (orgLoading) {
    return (
      <Loading center />
    );
  }

  if (orgError) {
    return (
      <ErrorMessage center devMessage={orgError.message}>
        Failed loading an organization
      </ErrorMessage>
    );
  }

  if (customFieldsError) {
    return (
      <ErrorMessage center devMessage={customFieldsError.message}>
        Failed loading custom data
      </ErrorMessage>
    );
  }

  if (!customFields) {
    return (
      <ErrorMessage center devMessage="data object is not set">
        Failed loading custom data
      </ErrorMessage>
    );
  }

  if (!orgData || !orgData.organization) {
    return (
      <ErrorMessage center devMessage="data object is not set">
        Failed loading an organization
      </ErrorMessage>
    );
  }

  const page = state?.sb_extra?.page ?? 'main';
  const secondaryPage = (() => {
    switch (page) {
      case 'documents':
        return (
          <DocumentsSubPage organizationId={organizationId} />
        );
      case 'gallery':
        return (
          <GallerySubPage organization={org!} />
        );
      case 'diversity':
        return (
          <DiversitySubPage organization={org!} />
        );
      case 'contacts':
        return (
          <ContactsSubPage
            adminForceShowSpEmail={adminForceShowSpEmail}
            organization={org!}
            organizationId={organizationId}
          />
        );
      case 'other':
        return (
          <FmFieldsSubPage
            organizationId={organizationId}
            fieldArray={customFields}
          />
        );
      default: return null;
    }
  })();
  const main = (
    <React.Fragment key="main">
      <SPOverview sp={org!} />
      <Box mb={2} ml={3}>
        <Typography variant="h6">
          Services
        </Typography>
      </Box>
      <div>
        {org!.services.map(({ service_id, name }) => (
          <Accordion
            key={service_id}
            expanded={expanded === service_id}
            onChange={handleChange(service_id)}
          >
            <AccordionSummary aria-controls={`content-${service_id}`} id={`header-${service_id}`}>
              <Grid container spacing={1}>
                <Grid item>
                  <Typography fontWeight={600}>
                    {name}
                  </Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              {expanded === service_id ? (
                <ServiceSpecific
                  org={org!}
                  organizationId={organizationId}
                  serviceId={service_id}
                  facilities={facilities}
                />
              ) : null}
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </React.Fragment>
  );

  return (
    <Grid
      container
      direction="row"
      spacing={0}
      className={`${classes.mainFrame} ${page !== 'main' ? classes.showSecondaryPage : ''}`}
    >
      <Grid item className={classes.frameItem}>
        {main}
      </Grid>
      {secondaryPage ? (
        <Grid item className={classes.frameItem}>
          {secondaryPage}
        </Grid>
      ) : null}
    </Grid>
  );
};
