import React, {
  useCallback, useContext, useEffect, useMemo,
} from 'react';
import {
  Button, Grid, Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { createStyles, makeStyles } from '@mui/styles';
import { useMutation } from '@apollo/react-hooks';
import { Add } from '@mui/icons-material';
import {
  ContactStruct, ContactTypeEnum,
  contactValidationSchema, emptyContact,
  useData as useKeyContactsData,
} from '../../../../../team/keyContacts/queries/useData';
import { ErrorSnackbar, useErrorSnackbar } from '../../../../../../notifications/ErrorSnackbar';
import { Loading } from '../../../../../../Loading';
import { ContactForm } from './ContactForm';
import { useHookSubPageControls } from '../../../useHookSubPageControls';
import { ORGANIZATION_CONTACTS_UPSERT } from './queries/UpsertContactsMutation';
import { UpsertContacts, UpsertContactsVariables } from '../../../../../../../api/types/UpsertContacts';
import { OnboardingContext } from '../../../OnboardingContext';
import { BOARDING_COMPLETENESS_QUERY } from '../../../queries/BoardingCompletenessQuery';
import { ButtonLoading } from '../../../../../../ButtonLoading';
import { InfoTooltip } from '../../../components/InfoTooltip';

export type KeyContactsFormValues = {
  [ContactTypeEnum.Primary]: ContactStruct[],
  [ContactTypeEnum.Signatory]: ContactStruct[],
  [ContactTypeEnum.Invoices]: ContactStruct[],
};

const useStyles = makeStyles(() => createStyles({
  contactTypeTitle: {
    textTransform: 'uppercase',
  },
}));

const tooltipDescriptions: Record<string, string> = {
  3: 'Providing your email and mobile number will ensure you receive notifications in a timely manner.'
    + ' The system uses email and texts alerts for communication, and you can update your notification'
    + ' settings at any time.',
  1: 'Person or team responsible for accounts payable. Invoices from Procursys relate to the transaction'
    + ' fees associated with awarded contracts.',
  2: 'Certain Property Managers use the e-signature capability powered by a DocuSign integration for contracting.'
    + ' This person would be responsible for signing Property Manager contracts if you are awarded and RFP.',
};

const transform = (v: ContactStruct[]) => v.map((c) => ((
  c.same_as_primary
  || (!c.first_name && !c.last_name && !c.phone && !c.email && !c.title)
) ? null : c));
const validationSchema = Yup.object().shape({
  [ContactTypeEnum.Primary]: Yup.array()
    .transform(transform)
    .of(contactValidationSchema.nullable()),
  [ContactTypeEnum.Signatory]: Yup.array()
    .transform(transform)
    .of(contactValidationSchema.nullable()),
  [ContactTypeEnum.Invoices]: Yup.array()
    .transform(transform)
    .of(contactValidationSchema.nullable()),
});

interface OnBoardingKeyContactsProps {
  mode: 'standalone' | 'integrated';
}

const contactTypeOrder = [
  ContactTypeEnum.Primary,
  ContactTypeEnum.Signatory,
  ContactTypeEnum.Invoices,
];

function getPrimaryContact(values: KeyContactsFormValues) {
  const primaryContacts = values[ContactTypeEnum.Primary];
  const contact = primaryContacts ? primaryContacts[0] : undefined;
  if (contact && contactValidationSchema.isValidSync(contact)) {
    return contact;
  }
  return undefined;
}

export const OnBoardingKeyContacts: React.FC<OnBoardingKeyContactsProps> = ({ mode }) => {
  const classes = useStyles();
  const boardingCtx = useContext(OnboardingContext);

  const { setViewedState } = boardingCtx;
  useEffect(() => {
    setViewedState('key_contacts', 1);
  }, [setViewedState]);

  const {
    types,
    typesMap,
    typeIdToNameMap,
    loading,
    orgContactTypesError,
    orgContactsError,
  } = useKeyContactsData();
  const orgContactsErrorSnackbar = useErrorSnackbar(orgContactsError);
  const orgContactTypesErrorSnackbar = useErrorSnackbar(orgContactTypesError);

  const [
    upsertContacts,
  ] = useMutation<UpsertContacts, UpsertContactsVariables>(ORGANIZATION_CONTACTS_UPSERT, {
    refetchQueries: [BOARDING_COMPLETENESS_QUERY],
  });

  const form = useFormik<KeyContactsFormValues>({
    initialValues: typesMap,
    enableReinitialize: true,
    validationSchema,
    async onSubmit(payload) {
      const pc = getPrimaryContact(payload);
      const contacts: ContactStruct[] = [];
      try {
        Object.entries(payload).forEach(([type_id, typeContacts]) => {
          typeContacts.forEach((contact) => {
            if (!contact?.email && !(pc?.email && contact.same_as_primary)) {
              return;
            }
            contacts.push({
              ...contact,
              type_id: +type_id,
              ...(contact.same_as_primary ? {
                first_name: pc?.first_name,
                last_name: pc?.last_name,
                email: pc?.email,
                phone: pc?.phone,
                title: pc?.title,
              } : {}),
            });
          });
        });

        const resp = await upsertContacts({
          variables: { contacts },
        });
        boardingCtx.setSuccessMsg('Successfully updated key contacts');
        return resp;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        return { errors: [e] };
      }
    },
  });

  const primaryContact = useMemo(
    () => getPrimaryContact(form.values),
    [form.values],
  );

  const getState = useCallback(() => (loading ? undefined : [{
    sectionName: 'Key Contacts',
    steps: [
      { name: 'Primary Contact', description: '', progress: primaryContact ? 1 : 0 },
      {
        name: 'Signatory Contact',
        description: '',
        progress: (form.values[ContactTypeEnum.Signatory]
          && contactValidationSchema.isValidSync(form.values[ContactTypeEnum.Signatory][0]))
        || (primaryContact && form.values[ContactTypeEnum.Signatory][0]?.same_as_primary) ? 1 : 0,
      },
      {
        name: 'Invoicing Contact',
        description: '',
        progress: (form.values[ContactTypeEnum.Invoices]
          && contactValidationSchema.isValidSync(form.values[ContactTypeEnum.Invoices][0]))
          || (primaryContact && form.values[ContactTypeEnum.Invoices][0]?.same_as_primary) ? 1 : 0,
      },
    ],
  }]), [primaryContact, loading, form.values]);
  useHookSubPageControls(mode, form, getState);

  if (loading) {
    return (
      <Loading center />
    );
  }

  const content = (
    <>
      <Grid
        container
        direction="column"
        spacing={2}
        component="form"
        onSubmit={form.handleSubmit}
      >
        <Grid item>
          <Typography variant="h4">
            Key Contacts
          </Typography>
        </Grid>
        {/* <Grid item> */}
        {/*  <Typography variant="body2">*/}
        {/*    Below please provide key contacts for your company.*/}
        {/*    {' '}*/}
        {/*    Please see sidebar description for more detailed information.*/}
        {/*  </Typography>*/}
        {/* </Grid> */}
        <Grid item container direction="column" spacing={3}>
          {Object.entries(form.values ?? {})
            .sort(([at], [bt]) => (
              contactTypeOrder.indexOf(+at as ContactTypeEnum)
              - contactTypeOrder.indexOf(+bt as ContactTypeEnum)
            ))
            .map(([type_id, contacts]) => (
              <Grid container item spacing={1} key={type_id}>
                <Grid
                  container
                  item
                  spacing={1}
                  alignItems="center"
                  style={{ marginBottom: +type_id === 3 ? -55 : 0 }}
                >
                  <Grid item>
                    <Typography variant="subtitle2" className={classes.contactTypeTitle}>
                      {typeIdToNameMap[type_id]}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <InfoTooltip title={tooltipDescriptions[type_id]} />
                  </Grid>
                </Grid>
                <Grid container item spacing={1}>
                  {contacts.map((val, idx) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Grid item key={`idx-${idx}`} flexGrow={1}>
                      <ContactForm
                        form={form}
                        type_id={type_id}
                        idx={idx}
                        isPrimary={+type_id === ContactTypeEnum.Primary}
                        primaryContact={primaryContact}
                      />
                    </Grid>
                  ))}
                </Grid>
                {types.find((t) => t.type_id === +type_id)?.allow_multiple_per_organization ? (
                  <Grid item container justifyContent="flex-end">
                    <Grid item>
                      <Button
                        size="small"
                        startIcon={<Add />}
                        onClick={() => {
                          form.setFieldValue(type_id, [
                            ...contacts,
                            { ...emptyContact(+type_id) },
                          ]);
                        }}
                      >
                        Add Contact
                      </Button>
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
            ))}
        </Grid>
        {mode === 'standalone' ? (
          <Grid item>
            <Button color="primary" type="submit" size="large" disabled={form.isSubmitting}>
              <ButtonLoading loading={form.isSubmitting} />
              Save
            </Button>
          </Grid>
        ) : null}
      </Grid>
      <ErrorSnackbar {...orgContactsErrorSnackbar}>
        Could not load organization contacts
      </ErrorSnackbar>
      <ErrorSnackbar {...orgContactTypesErrorSnackbar}>
        Could not load organization contacts types
      </ErrorSnackbar>
    </>
  );

  return content;
};

export default OnBoardingKeyContacts;
