import React from 'react';
import { Grid, Tooltip } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { FmPreferenceButton } from '../../../fmSpStat/PreferenceButton/FmPreferenceButton';

export interface SpSidebarTitleProps {
  title: string;
  spOrganizationId?: number;
  logo?: string;
}

const useStyles = makeStyles((theme) => createStyles({
  logo: {
    height: '30px',
    margin: '0px 0 -8px',
  },
  title: {
    [theme.breakpoints.down('md')]: {
      maxWidth: 'calc(100vw - 150px)',
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: '450px',
    },
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

export const SpSidebarTitle: React.FC<SpSidebarTitleProps> = ({
  title,
  spOrganizationId,
  logo,
}) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      {logo ? (
        <Grid item>
          <img className={classes.logo} src={logo} alt={`${title} Logo`} />
        </Grid>
      ) : null}
      <Grid
        item
        className={classes.title}
      >
        <Tooltip title={title} arrow placement="top-start">
          <span>{title}</span>
        </Tooltip>
      </Grid>
      <Grid item>
        {spOrganizationId ? <FmPreferenceButton size="small" spOrganizationId={spOrganizationId} /> : null}
      </Grid>
    </Grid>
  );
};
