import React, { useEffect, useMemo, useState } from 'react';
import {
  Box, Grid, Link, Tooltip, Typography,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import sanitizeHtml from 'sanitize-html';

import { formatPhoneInternationalPretty } from '../../../../../../utils/phone';
import clock from './icons/clock.svg';
import phone from './icons/phone.svg';
import check from './icons/checkmark.svg';
import link from './icons/link.svg';
import arrow_forward from './icons/arrow_forward.svg';
import { NoData } from '../../../../../text/NoData';
import { ServiceProvider_organization } from '../../../../../../api/types/ServiceProvider';
import { useSpSidebarHandlers } from '../SpSidebarProvider';
import { useProfile } from '../../../../../../api/user/profile';

export interface SPOverviewProps {
  sp: ServiceProvider_organization;
}

const useStyles = makeStyles((theme) => createStyles({
  box: {
    borderRadius: 12,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#21212114',
    margin: theme.spacing(0, 3, 3, 3),
  },
  innerData: {
    margin: theme.spacing(3, 3, 3, 3),
  },
  icon: {
    color: '#D9D9D9',
    marginBottom: -2,
    height: 13,
    width: 13,
  },
  iconBig: {},

  infoLinkItem: {
    zIndex: 10,
  },
  aboutContainer: {
    fontSize: 14,
    color: '#00000099',
  },
  clockIcon: {
    backgroundSize: 'contain',
    background: `url(${clock})`,
  },
  linkIcon: {
    backgroundSize: 'contain',
    background: `url(${link})`,
  },
  checkIcon: {
    backgroundSize: 'contain',
    background: `url(${check})`,
  },
  phoneIcon: {
    backgroundSize: 'contain',
    background: `url(${phone})`,
  },
  arrowForwardIcon: {
    backgroundSize: 'contain',
    background: `url(${arrow_forward})`,
  },

  COIIcon: {
    color: '#00B3A6',
  },
  COIText: {
    color: '#018786',
    whiteSpace: 'nowrap',
  },

  lockIcon: {
    marginLeft: theme.spacing(1),
  },

  linkContainer: {
    cursor: 'pointer',
    padding: theme.spacing(1.5, 3, 1.5, 3),
    borderTop: '1px solid #21212114',
  },
  link: {
    textTransform: 'uppercase',
    fontSize: 14,
  },
}));

export const SPOverview = ({ sp }: SPOverviewProps) => {
  const profile = useProfile();
  const classes = useStyles();
  const { modifyExtra } = useSpSidebarHandlers();

  const websiteName = useMemo(() => (
    sp.website_url?.replace(/http(s?):\/\/(www\.)?/gi, '').replace(/\/[\s\S]+/gi, '/...')
  ), [sp]);
  const yearSince = sp.spProfile?.years_in_business;

  const [calcHeightElement, setCalcHeightElement] = useState<HTMLDivElement>();
  useEffect(() => {
    const div = document.createElement('div');
    div.style.fontStyle = '14px';
    div.style.maxWidth = '540px';
    div.style.position = 'absolute';
    div.style.top = '-9999px';
    document.body.appendChild(div);
    setCalcHeightElement(div);
    return () => {
      document.body.removeChild(div);
      setCalcHeightElement(undefined);
    };
  }, []);
  const [aboutExpanded, setAboutExpanded] = useState(false);
  const [showExpandBtn, setShowExpandBtn] = useState(false);
  const aboutText = useMemo(() => {
    if (!calcHeightElement) {
      return '...';
    }
    const wordsPieces = sanitizeHtml(
      sp?.about ?? '',
      { allowedTags: [] },
    ).split(' ') ?? [];
    const getTextHeight = (txt: string) => {
      if (!calcHeightElement) {
        return 0;
      }
      calcHeightElement.innerText = txt;
      return calcHeightElement.getBoundingClientRect().height;
    };
    if (!aboutExpanded) {
      let lastHeight = getTextHeight(wordsPieces.join(' '));
      let iter = 0;
      while (lastHeight > 75) {
        wordsPieces.pop();
        lastHeight = getTextHeight(wordsPieces.join(' '));
        iter += 1;
      }
      if (iter) {
        setShowExpandBtn(true);
      }
      return `${wordsPieces.join(' ')}${iter ? '...' : ''}`;
    }

    return sp.spProfile?.about;
  }, [aboutExpanded, sp, calcHeightElement]);

  const links = useMemo(() => ([
    { name: 'Contact Information', to: 'contacts' },
    { name: 'Diversity', to: 'diversity' },
    { name: 'Documents', to: 'documents' },
    { name: 'Gallery', to: 'gallery' },
    { name: `${profile.organization.name} Notes`, to: 'other', isPrivate: true },
  ]), [profile.organization.name]);

  return (
    <Box className={classes.box}>
      <Box className={classes.innerData}>
        <Grid container direction="column" spacing={1}>
          <Grid item container direction="row" spacing={2}>
            <Grid
              item
              container
              direction="row"
              spacing={1}
              alignItems="center"
              maxWidth="max-content"
              className={classes.infoLinkItem}
            >
              <Grid item>
                <div className={`${classes.icon} ${classes.iconBig} ${classes.linkIcon}`} />
              </Grid>
              <Grid item>
                {sp.website_url ? (
                  <Link href={sp.website_url ?? ''} target="_blank" rel="noreferrer" style={{ cursor: 'pointer' }}>
                    {websiteName}
                  </Link>
                ) : <NoData>No website</NoData>}
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction="row"
              spacing={1}
              alignItems="center"
              maxWidth="max-content"
              className={classes.infoLinkItem}
            >
              <Grid item>
                <div className={`${classes.icon} ${classes.iconBig} ${classes.phoneIcon}`} />
              </Grid>
              <Grid item>
                {sp.phone_number ? (
                  <Link href={`tel:${sp.phone_number}`} target="_blank" rel="noreferrer" style={{ cursor: 'pointer' }}>
                    {formatPhoneInternationalPretty(sp.phone_number)}
                  </Link>
                ) : <NoData>No Phone Number</NoData>}
              </Grid>
            </Grid>
          </Grid>
          <Grid item container direction="row" spacing={2}>
            {yearSince ? (
              <Grid item container direction="row" spacing={1} alignItems="center" maxWidth="max-content">
                <Grid item>
                  <div className={`${classes.icon} ${classes.clockIcon}`} />
                </Grid>
                <Grid item>
                  Since
                  {' '}
                  {yearSince}
                </Grid>
              </Grid>
            ) : null}
            <Grid item container direction="row" spacing={1} alignItems="center" maxWidth="max-content" wrap="nowrap">
              {sp?.hasCOI ? (
                <>
                  <Grid item>
                    <div className={`${classes.icon} ${classes.COIIcon} ${classes.checkIcon}`} />
                  </Grid>
                  <Grid item className={classes.COIText}>
                    COI on file
                  </Grid>
                </>
              ) : null}
              {sp?.hasW9 ? (
                <>
                  <Grid item>
                    <div className={`${classes.icon} ${classes.COIIcon} ${classes.checkIcon}`} />
                  </Grid>
                  <Grid item className={classes.COIText}>
                    W9 on file
                  </Grid>
                </>
              ) : null}
              {!sp?.hasCOI && !sp?.hasW9 ? (
                <Grid item>
                  <NoData>No COI/W9 on file</NoData>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          <Grid item className={classes.aboutContainer}>
            <span>{aboutText}</span>
            {' '}
            {showExpandBtn ? (
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setAboutExpanded((p) => !p);
                }}
              >
                {aboutExpanded ? 'SHOW LESS' : 'SHOW MORE'}
              </a>
            ) : null}
          </Grid>
        </Grid>
      </Box>

      <Grid container direction="column">
        {links.map(({ name, to, isPrivate }) => (
          <Grid
            key={to}
            item
            container
            className={classes.linkContainer}
            justifyContent="space-between"
            alignItems="center"
            onClick={() => {
              modifyExtra({ page: to });
            }}
          >
            <Grid item>
              <Typography color="primary" fontWeight={500} className={classes.link}>
                {name}
                {isPrivate ? (
                  <Tooltip title={`Only visible to ${profile.organization.name}`}>
                    <span>
                      <FontAwesomeIcon icon="lock-alt" className={classes.lockIcon} />
                    </span>
                  </Tooltip>
                ) : null}
              </Typography>
            </Grid>
            <Grid item>
              <div className={`${classes.icon} ${classes.arrowForwardIcon}`} />
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
