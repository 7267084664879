import React from 'react';
import {
  Box, Button, ButtonGroup, Checkbox, FormControlLabel, Grid, TextField, Tooltip,
} from '@mui/material';
import { FormikHandlers, FormikProps } from 'formik';
import { FormikState } from 'formik/dist/types';
import { get } from 'lodash';
import { PhoneNumberMaskInput } from '../../../../../../form/PhoneNumberMaskInput';
import { KeyContactsFormValues } from './OnBoardingKeyContacts';
import { ContactStruct } from '../../../../../team/keyContacts/queries/useData';

interface ContactFormProps {
  form: FormikHandlers & FormikState<KeyContactsFormValues> & FormikProps<KeyContactsFormValues>,
  type_id: string,
  idx: number,
  isPrimary: boolean,
  primaryContact?: ContactStruct;
}

export const ContactForm: React.FC<ContactFormProps> = ({
  form,
  type_id,
  idx,
  isPrimary,
  primaryContact,
}) => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
  } = form;
  const suffixPrefix = `[${type_id}].[${idx}]`;
  const sameAsPrimaryField = `${suffixPrefix}.same_as_primary`;
  const firstNameField = `${suffixPrefix}.first_name`;
  const lastNameField = `${suffixPrefix}.last_name`;
  const titleField = `${suffixPrefix}.title`;
  const emailField = `${suffixPrefix}.email`;
  const phoneField = `${suffixPrefix}.phone`;

  const disabled = get(values, sameAsPrimaryField);

  return (
    <Box mt={1}>
      <Grid container direction="row" spacing={2}>
        <Grid
          container
          item
          sm={12}
          flexGrow={1}
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item>
            {!isPrimary && idx === 0 ? (
              <Tooltip
                arrow
                placement="top"
                title={!primaryContact ? 'Fill in primary contact to unlock this checkbox' : undefined}
              >
                <FormControlLabel
                  control={(
                    <Checkbox
                      name={sameAsPrimaryField}
                      color="primary"
                      checked={!!primaryContact && get(values, sameAsPrimaryField)}
                      disabled={!primaryContact}
                      onChange={handleChange}
                    />
                  )}
                  label="Same as primary contact"
                />
              </Tooltip>

            ) : null}
          </Grid>
          <Grid item justifySelf="flex-end">
            <ButtonGroup>
              <Button
                size="small"
                color="secondary"
                onClick={() => {
                  form.setFieldValue(sameAsPrimaryField, false);
                  form.setFieldValue(firstNameField, '');
                  form.setFieldValue(lastNameField, '');
                  form.setFieldValue(emailField, '');
                  form.setFieldValue(phoneField, '');
                  form.setFieldValue(titleField, '');
                }}
              >
                Clear
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
        <Grid item xs={6} sm={4}>
          <TextField
            name={firstNameField}
            label="First Name *"
            fullWidth
            value={disabled ? primaryContact?.first_name : get(values, firstNameField)}
            disabled={disabled}
            onChange={handleChange}
            onBlur={handleBlur}
            error={get(touched, firstNameField) && !!get(errors, firstNameField)}
            helperText={get(touched, firstNameField) && get(errors, firstNameField)}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <TextField
            name={lastNameField}
            label="Last Name *"
            fullWidth
            value={disabled ? primaryContact?.last_name : get(values, lastNameField)}
            disabled={disabled}
            onChange={handleChange}
            onBlur={handleBlur}
            error={get(touched, lastNameField) && !!get(errors, lastNameField)}
            helperText={get(touched, lastNameField) && get(errors, lastNameField)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            name={titleField}
            label="Title"
            type="title"
            fullWidth
            value={disabled ? primaryContact?.title : get(values, titleField)}
            disabled={disabled}
            onChange={handleChange}
            onBlur={handleBlur}
            error={get(touched, titleField) && !!get(errors, titleField)}
            helperText={get(touched, titleField) && get(errors, titleField)}
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <TextField
            name={emailField}
            label="Email *"
            type="email"
            fullWidth
            value={disabled ? primaryContact?.email : get(values, emailField)}
            disabled={disabled}
            onChange={handleChange}
            onBlur={handleBlur}
            error={get(touched, emailField) && !!get(errors, emailField)}
            helperText={get(touched, emailField) && get(errors, emailField)}
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <TextField
            name={phoneField}
            label="Phone"
            type="phonenumber"
            fullWidth
            value={disabled ? primaryContact?.phone : get(values, phoneField)}
            disabled={disabled}
            onChange={handleChange}
            onBlur={handleBlur}
            error={get(touched, phoneField) && !!get(errors, phoneField)}
            helperText={get(touched, phoneField) && get(errors, phoneField)}
            InputProps={{ inputComponent: PhoneNumberMaskInput as any }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
